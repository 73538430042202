import React from "react"
import styled from "styled-components";

const JaggedSpacer:React.FC<{ backgroundColor: string }> = ({ backgroundColor }) =>
{
  return (
    <Polygon color={backgroundColor} />
    )
}

const Polygon = styled.div`
  height: 50px;
  background-color: ${props => props.color};
  position: relative;
  clip-path: polygon(
    100% 90%,
    75% 0,
    18% 98%,
    0 20%,
    0 100%,
    100% 100%);
  margin-bottom: -1px;
`

export default JaggedSpacer;