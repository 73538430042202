import React from "react"
import styled from "styled-components";
import FullRow from "./full-row";
import JaggedSpacer from "./jagged-spacer";

const JaggedSection:React.FC<{
  backgroundColor: string, 
  nextBackgroundColor?: string,
  children: React.ReactNode
}> = ({ 
  backgroundColor,
  nextBackgroundColor = "var(--color-gray-1000)",
  children 
}) =>
{
  return (
    <FullRow>
        <JaggedSpacer backgroundColor={backgroundColor}/>
        <Content color={backgroundColor}>
          {children}
          <JaggedSpacer backgroundColor={nextBackgroundColor}/>
        </Content>
    </FullRow>
    )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${props => props.color};
`

export default JaggedSection;