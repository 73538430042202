import React from "react"
import styled from "styled-components";

const FullRow:React.FC<{
  children: React.ReactNode
}> = ({ 
  children 
}) =>
{
  return (
    <Wrapper>
        {children}
    </Wrapper>
    )
}

const Wrapper = styled.div`
  width: 100%;
  grid-column: 1 / 6;
`

export default FullRow;