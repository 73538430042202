import * as React from "react"
import styled from "styled-components/macro"
import AboutMe from "../components/about-me"
import CenterRow from "../components/center-row"
import JaggedSection from "../components/jagged-section"

const ResourcesPage = () => {
  return (
    <>
    <CenterRow wide>
      <Title>Resources</Title>
      <Content>
        <h3>Presentations</h3>
        <p>Systems Thinking for Engineering Leaders <a href="/systemsthinking-sqlsat.pdf">(PDF)</a> <a href="https://www.slideshare.net/svickn/systems-thinking-for-engineering-leaderspdf">(SlideShare)</a></p>
        <p>Tabletop Role-playing Games at Work for Fun &amp; Profit <a href="https://github.com/williamadba/Public-Presentations/raw/main/SQLSaturday%20Baton%20Rouge%202022/Tabletop%20Role-playing%20Games%20at%20Work%20for%20Fun%20%26%20Profit.pptx">(PPTX)</a></p>
      </Content>
    </CenterRow>
    <JaggedSection backgroundColor='var(--color-primary)'>
      <ComingSoon>
        More resources coming soon!
      </ComingSoon>
    </JaggedSection>
    <AboutMe />
    </>
  )
}

const Title = styled.h1`
  text-align: center;
  padding-top: 1.5em;
  padding-bottom: 1.2em;
`

const SectionTitle = styled.h2`
  padding-bottom: 0.7em;
`

const ComingSoon = styled.h1`
  color: var(--color-gray-1000);
  padding: 42px 0;
  text-align: center;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 64px;
`

export default ResourcesPage
