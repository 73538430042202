import React from 'react'
import styled from 'styled-components'
import CenterRow from './center-row'

const AboutMe:React.FC<{}> = () => {
  return ( 
    <CenterRow>
    <Wrapper>
      <SectionTitle>About Me</SectionTitle>
<Content>
<p>
  Hi! I’m Stacy Vicknair and I am an engineering leader who has helped
  level up the maturity of B2B SaaS teams for the better part of nine years. I've 
  given talks about leadership in software development for conferences
  and organizations both online and throughout the south central United 
  States.&nbsp;
  {/* <em>
      (You can check out my <a href="/contact">contact page</a> for
    more info.)
  </em> */}
</p>
<p>
  <Image src="/sqlsat.png" alt="Stacy Vicknair" />
</p>
<p>
  I believe that you can have a world class team while still maintaining 
  the work-life balance that's right for you.&nbsp;
  <strong>Let me help you find balance, reclaim your schedule, and love your job again!</strong>
</p>
</Content>
    </Wrapper>
    </CenterRow>
  )
}

const Wrapper = styled.div`
  padding: 56px 0 80px 0;

  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
`

const Content = styled.div`
  padding: 0 32px;

  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;

  & > p {
    padding-bottom: 24px;
  }
`

const SectionTitle = styled.h1`
  padding: 12px 0;
`

const Image = styled.img`
  width: 100%;
`

export default AboutMe