import React from "react"
import styled from "styled-components";

const CenterRow:React.FC<{
  children: React.ReactNode,
  wide?: boolean
}> = ({ 
  children,
  wide = false 
}) =>
{
  const wrapper = wide ? WideWrapper : DefaultWrapper;
  return React.createElement(wrapper, {}, children);
}

const DefaultWrapper = styled.div`
  width: 100%;
  grid-column: 1 / 6;

  @media (min-width: 992px) {
    grid-column: 3;
  }
`

const WideWrapper = styled(DefaultWrapper)`
@media (min-width: 992px) {
    grid-column: 2 / 5;
  }
`

export default CenterRow;